import * as React from "react";
import ReactMarkdown from "react-markdown";
import MathJax from "react-mathjax";
import RemarkMathPlugin from "remark-math";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";
import { BLOCKS } from "@contentful/rich-text-types";

const MarkdownRender = ({ source, ...props }) => {
  const newProps = {
    ...props,
    plugins: [RemarkMathPlugin],
    renderers: {
      ...props.renderers,
      math: ({ value }) => <BlockMath>{value}</BlockMath>,
      inlineMath: ({ value }) => <InlineMath>{value}</InlineMath>,
    },
  };
  console.log(source);
  const options = {
    renderText: (text) => {
      return <ReactMarkdown {...newProps} source={text} />;
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      const UnTaggedChildren = documentToReactComponents(node, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => children,
          [BLOCKS.LIST_ITEM]: (node, children) => children,
        },
      });

      return <li>{UnTaggedChildren}</li>;
    },
  };
  const markDown = documentToReactComponents(source, options);

  return (
    <MathJax.Provider input="tex">
      {markDown}
      {/* <ReactMarkdown {...newProps} /> */}
    </MathJax.Provider>
  );
};

export default MarkdownRender;
